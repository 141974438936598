<template>
  <div>
    <!--begin::Advance Table Widget 9-->
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            Templates
          </span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm"></span>
        </h3>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
        <b-table
          responsive
          striped
          hover
          :items="items"
          :fields="fields"
          :busy="isBusy"
          empty-text="No records"
        >
          <template v-slot:cell(action)="data">
            <a
              class="btn btn-success font-size-sm text-uppercase"
              @click="uploadFile(data.item.uuid)"
            >
              <i class="flaticon-upload"></i> Upload
            </a>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Advance Table Widget 9-->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { LIST_TEMPLATES } from "@/core/services/store/template.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import slugify from "slugify";
import { moment } from "@/lib/constants";
import ApiService from "@/core/services/api.service";

export default {
  name: "TemplateImport",
  /*components: {},*/
  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: "name",
          sortable: true
        },
        {
          key: "type",
          sortable: false
        },
        {
          key: "created_at",
          label: "Created",
          sortable: true,
          formatter: value => {
            return moment(value).format("YYYY-MM-DD");
          }
        },
        {
          key: "action",
          label: "Action",
          sortable: false
        }
      ],
      items: [],
      iframe: null,
      iframeContainer: null,
      onMessage: null,
      selectedTemplateUuid: null
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Templates" }]);

    this.isBusy = true;
    this.$store.dispatch(LIST_TEMPLATES).then(() => {
      this.isBusy = false;
    });

    this.$nextTick(() => {
      const hljs = this.$el.querySelectorAll(".hljs");
      hljs.forEach(hl => {
        hl.classList.remove("hljs");
        hl.classList.add(`language-${hl.classList[1]}`);
      });
    });

    this.items = this.templates;

    this.iframeContainer = document.createElement("div");
    this.iframe = document.createElement("iframe");

    this.iframeContainer.style.position = "fixed";
    this.iframeContainer.style.top = 0;
    this.iframeContainer.style.left = 0;
    this.iframeContainer.style.width = "100%";
    this.iframeContainer.style.height = "100vh";
    this.iframeContainer.style.zIndex = "9999999";

    this.iframe.style.width = "100%";
    this.iframe.style.height = "100vh";
    this.iframe.style.border = "none";

    this.iframeContainer.appendChild(this.iframe);

    this.onMessage = this.onMessageHandler;
    window.addEventListener("message", this.onMessage);
  },

  beforeDestroy() {
    if ([...document.body.children].indexOf(this.iframeContainer) > -1) {
      document.body.removeChild(this.iframeContainer);
    }
    window.removeEventListener("message", this.onMessage);
  },

  methods: {
    onMessageHandler(e) {
      const { data } = e;
      if (data.key === `${this.selectedTemplateUuid}close`) {
        document.body.removeChild(this.iframeContainer);
      }
    },
    uploadFile(uuid) {
      this.selectedTemplateUuid = uuid;
      document.body.appendChild(this.iframeContainer);
      this.iframe.src = `${ApiService.getServerUrl()}/import-popup/${uuid}`;
    }
  },
  computed: {
    ...mapGetters(["templates"])
  },
  watch: {
    templates(newValue, oldValue) {
      if (newValue.length != oldValue.length) {
        this.items = newValue;
      }
    }
  }
};
</script>
